.profile-modal > .modal-content {
  width: 700px
}

.link {
  text-decoration: none;
  color: white;
  padding: 8px;
}

.active {
  color: #0d6efd;
  text-decoration: none;
  padding: 8px;
}
