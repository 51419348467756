.container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row > * {
  width: fit-content;
}