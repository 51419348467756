.dropdown-filter-menu .items-list {
  max-height: 300px;
  overflow-y: auto;
}

.form {
  width: 100%;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 35px;
  color: black;
  background-color: white;

  &:after {
    position: absolute;
    right: 12px;
    top: 17px;
  }
}

.empty-value {
  color: #ffad00;
}

.toggle {
  cursor: pointer;
  border: none;
  background: inherit;
  &:after {
    visibility: hidden;
  }

  &:hover, &:focus, &.show {
    &:after {
      visibility: visible;
    }
  }
}