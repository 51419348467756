.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../../../public/background.jpg');
  background-size: 100% 100%;
  height: 100vh;

  .label {
    margin-top: -20vh;
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }

  .login {
    font-size: 1.2rem;
  }
}