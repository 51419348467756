.close {
  position: absolute;
  top: 0;
  right: -20px;
  cursor: pointer;
  &:hover {
    color: #dc3545;
  }
}

.print {
  position: absolute;
  top: 24px;
  right: -20px;
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(17%) sepia(40%) saturate(7406%) hue-rotate(345deg) brightness(111%) contrast(73%);
  }
}

.pdf-viewer {
  width: 100%;

  .modal-dialog {
    max-width: 804px;
    position: relative;
  }

  .content {
    max-width: 804px;
    width: 804px;
    background-color: unset;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.page {
  margin-bottom: 5px;
}

.spinner {

}