.amount {
  color: white;

  &Editable {
    cursor: pointer;
    color: #dc3545;
    font-weight: bolder;
    &:focus {
      cursor: text;
      color: white;
      border-bottom: solid;
    }
  }
}
