.component-margin {
    margin-right: 10px;
}

.content-margin{
   margin-left: 10px;
}

.inline{
   display: inline-block;
}

#dateRangeHeader {
  margin-right: 30px;
}

.select-used-cost-center {
  margin-left: 30px;
}