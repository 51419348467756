.search-block {
   display: flex;
   justify-content: flex-end;
}

.advanced{
    margin-left: 10px;
}

.box {
   animation: fade-in 0.5s ease-in;
   padding: 5px 10px;
   width: fit-content;
   font-family: Arial;
}

