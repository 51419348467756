.account:hover {
  background: #c4c4c6;
  color: #4b4b4b;
}

.profileButton {
  padding: 7px;
  color: #454040;
}

.profileButton:hover {
  background: #797979;
  color: #ffffff;
}

.default-bank-account {
  width: 123px;
  font-weight: bold;
  text-align: center;
}