.history-dialog {
  max-width: 800px;
}

.dialog-body {
  max-height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}