@import '~bootstrap/scss/bootstrap';
@import 'react-toastify/dist/ReactToastify.css';

body {
  background: lightgray;
}

.modal-content {
  background: rgba( 33, 37, 41);
  color: white;
  border: 2px white solid;
}

.error-text {
  color: red;
}

.approved {
  cursor: pointer;
  color: green;
}

.rejected {
  cursor: pointer;
  color: #dc3545;
}

.loading-indicator {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  vertical-align: center;
  background-color:rgba(0, 0, 0, 0.5);
  position: fixed;
}