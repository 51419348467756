.bank-account {
  width: 210px;
}

.filter-dropdown button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
}

.bank-account, .vat-code, .accounting-number, .cost-center {
  width: 180px;
}


.dta-date, .payment-date,  {
  width: 100px;
}

.action {
  width: 40px;
  cursor: default;
}

.status {
  width: 50px;
  cursor: pointer;
}

.amount {
  width: 110px;
}

.currency {
  width: 30px;
  cursor: pointer;
}

.name {
  width: 100px;
  cursor: pointer;
}

.company{
  width: 250px;
  cursor: pointer;
}

td {
  vertical-align: middle;
}

.top{
  vertical-align: top;
}

.approval-tooltip > .tooltip-inner {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selection {
  cursor: default;
}

.actions-wrapper {
  margin: 0 auto 0 16px;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}